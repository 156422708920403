<script setup lang="ts">
import Button from '@/Components/Button/Button.vue';
import DataCard from '@/Components/DataCard.vue';
import Title from '@/Components/Title/Title.vue';
import StaffLayout from '@/Layouts/StaffLayout.vue';
import { mapErrors } from '@/utilities';
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';
import { useI18n } from 'vue-i18n';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const route = inject('route') as any;

const { t } = useI18n();

defineProps({
    allTempWorkers: Object,
    allFreelancers: Object,
    allLocations: Object,
});

const destinationOptions = [
    { value: 'none', label: t('None') },
    { value: 'all', label: t('All') },
    { value: 'specific', label: t('Specific') },
];

const form = useForm({
    temp_worker_type: 'none',
    temp_workers: [],
    freelancer_type: 'none',
    freelancers: [],
    location_type: 'none',
    locations: [],
    message_nl: '',
    message_en: '',
    send_via_push_notification: false,
    send_via_email: false,
});

const sendForm = () => {
    form.transform((data) => {
        if (data.temp_worker_type !== 'specific') {
            data.temp_workers = [];
        }
        if (data.freelancer_type !== 'specific') {
            data.freelancers = [];
        }

        if (data.location_type !== 'specific') {
            data.locations = [];
        }

        return data;
    }).post(route('staff.send-notification.store'), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => {
            form.errors = mapErrors(errors);
        },
    });
};
</script>

<template>
    <StaffLayout :title="$t('Send notification')">
        <template #default>
            <Title :title="$t('Send notification')"></Title>
            <FormKit type="form" id="send-notification-form" @submit="sendForm" :actions="false">
                <DataCard :title="$t('Temp workers')" class="my-5">
                    <FormKit
                        v-model="form.temp_worker_type"
                        type="radio"
                        name="temp_worker_type"
                        :label="$t('Type')"
                        :options="destinationOptions"
                        :classes="{
                            options: 'flex flex-col md:flex-row',
                        }"
                        validation="required"
                    />

                    <div v-if="form.temp_worker_type === 'specific'">
                        <FormKit
                            v-model="form.temp_workers"
                            type="checkbox"
                            name="temp_workers"
                            :label="$t('Temp workers')"
                            :options="allTempWorkers"
                            :classes="{
                                options: 'flex flex-col md:flex-wrap md:flex-row',
                                option: 'md:basis-1/2 lg:basis-1/3 xl:basis-1/4',
                            }"
                            validation="required"
                        />
                    </div>
                </DataCard>

                <DataCard :title="$t('Freelancers')" class="my-5">
                    <FormKit
                        v-model="form.freelancer_type"
                        type="radio"
                        name="freelancer_type"
                        :label="$t('Type')"
                        :options="destinationOptions"
                        :classes="{
                            options: 'flex flex-col md:flex-row',
                        }"
                        validation="required"
                    />

                    <div v-if="form.freelancer_type === 'specific'">
                        <FormKit
                            v-model="form.freelancers"
                            type="checkbox"
                            name="freelancers"
                            :label="$t('Freelancers')"
                            :options="allFreelancers"
                            :classes="{
                                options: 'flex flex-col md:flex-wrap md:flex-row',
                                option: 'md:basis-1/2 lg:basis-1/3 xl:basis-1/4',
                            }"
                            validation="required"
                        />
                    </div>
                </DataCard>

                <DataCard :title="$t('Locations')" class="my-5">
                    <FormKit
                        v-model="form.location_type"
                        type="radio"
                        name="location_type"
                        :label="$t('Type')"
                        :options="destinationOptions"
                        :classes="{
                            options: 'flex flex-col md:flex-row',
                        }"
                    />

                    <div v-if="form.location_type === 'specific'">
                        <FormKit
                            v-model="form.locations"
                            type="checkbox"
                            name="locations"
                            :label="$t('Locations')"
                            :options="allLocations"
                            :classes="{
                                options: 'flex flex-col md:flex-wrap md:flex-row',
                                option: 'md:basis-1/2 lg:basis-1/3 xl:basis-1/4',
                            }"
                            validation="required"
                        />
                    </div>
                </DataCard>

                <DataCard :title="$t('Message')" class="grid gap-5 my-5">
                    <ul class="list-disc">
                        <li>
                            {{ $t('Messages may only contain at most 160 characters') }}
                        </li>
                        <li>
                            {{ $t('Line breaks are ignored') }}
                        </li>
                    </ul>
                    <FormKit
                        v-model="form.message_nl"
                        type="textarea"
                        name="message_nl"
                        label="NL"
                        :classes="{
                            input: 'w-full',
                        }"
                        validation="required|length:3,160"
                        :validation-label="$t('Message [{LOCALE}]', { LOCALE: 'NL' })"
                    />

                    <FormKit
                        v-model="form.message_en"
                        type="textarea"
                        name="message_en"
                        label="EN"
                        :classes="{
                            input: 'w-full',
                        }"
                        validation="required|length:3,160"
                        :validation-label="$t('Message [{LOCALE}]', { LOCALE: 'EN' })"
                    />

                    <FormKit
                        v-model="form.send_via_push_notification"
                        type="checkbox"
                        name="send_via_push_notification"
                        :label="$t('Send via push notification')"
                    />

                    <FormKit
                        v-model="form.send_via_email"
                        type="checkbox"
                        name="send_via_email"
                        :label="$t('Send via email')"
                    />
                </DataCard>

                <Button :processing="form.processing" :recentlySuccessful="form.recentlySuccessful">
                    <div class="flex items-center gap-2">
                        <div class="w-full">
                            {{ $t('Send notification') }}
                        </div>
                    </div>
                </Button>
            </FormKit>
        </template>
    </StaffLayout>
</template>
